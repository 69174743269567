<template>
	<div>
    <v-card>
      <v-toolbar
        flat
        color="primary"
        dark
      >
        <v-toolbar-title v-if="club.MIRClub">{{club.MIRClub.name}}</v-toolbar-title>
      </v-toolbar>
      <v-tabs  :vertical="!isMobilephone">
        <v-tab>
          <v-icon left>
            fa-map-marker
          </v-icon>
          Egyesület
        </v-tab>
				<v-tab>
          <v-icon left>
            fa-users
          </v-icon>
          Csoportok
        </v-tab>
				<v-tab>
          <v-icon left>
            fa-user
          </v-icon>
          Felhasználók
        </v-tab>

        <v-tab-item> <!--Egyesület-->
          <v-simple-table>
            <tbody>
              <tr>
                <th style="text-align: right">Elnök</th>
                <td style="text-align: center">
                  <span v-if="club.Chairman">
                    <v-btn text :to="{name: 'user', params: {id: club.Chairman.id}}">{{club.Chairman.fullname}}</v-btn>
                  </span>
                  <span v-else>
                    {{club.MIRClub.chairman}}
                  </span>
                </td>
                <td style="text-align: center">
                  <v-btn rounded color="success" @click="dialogs.editClub.changedProperty='chairmanId', dialogs.editClub.changedValue='',dialogs.editClub.show=true"><v-icon>fa-database</v-icon>Kiválasztás KIF-ből</v-btn>
                  <v-btn v-if="club.Chairman" rounded color="red accent-4" @click="dialogs.editClub.changedProperty='chairmanId', dialogs.editClub.changedValue=null,editClub()"><v-icon>fa-trash</v-icon>KIF-összekötés törlése</v-btn>
                </td>
              </tr>
              <tr>
                <th style="text-align: right">Szakosztályvezető</th>
                <td style="text-align: center">
                  <span v-if="club.DepartmentDirector">
                    <v-btn text :to="{name: 'user', params: {id: club.DepartmentDirector.id}}">{{club.DepartmentDirector.fullname}}</v-btn>
                  </span>
                  <span v-else>
                    {{club.MIRClub.departmentDirector}}
                  </span>
                </td>
                <td style="text-align: center">
                  <v-btn rounded color="success" @click="dialogs.editClub.changedProperty='departmentDirectorId', dialogs.editClub.changedValue='',dialogs.editClub.show=true"><v-icon>fa-database</v-icon>Kiválasztás KIF-ből</v-btn>
                  <v-btn v-if="club.DepartmentDirector" rounded color="red accent-4" @click="dialogs.editClub.changedProperty='departmentDirectorId', dialogs.editClub.changedValue=null,editClub()"><v-icon>fa-trash</v-icon>KIF-összekötés törlése</v-btn>
                </td>
              </tr>
              <tr>
                <th style="text-align: right">Kölyökatléta-felelős</th>
                <td style="text-align: center">
                  <v-btn v-if="club.KAAdmin" text :to="{name: 'user', params: {id: club.KAAdmin.id}}">{{club.KAAdmin.fullname}}</v-btn>
                  <span v-else>-</span>
                </td>
                <td style="text-align: center">
                  <v-btn rounded color="success" @click="dialogs.editClub.changedProperty='adminId', dialogs.editClub.changedValue='',dialogs.editClub.show=true"><v-icon>fa-database</v-icon>Kiválasztás KIF-ből</v-btn>
                  <v-btn v-if="club.KAAdmin" rounded color="red accent-4" @click="dialogs.editClub.changedProperty='adminId', dialogs.editClub.changedValue=null,editClub()"><v-icon>fa-trash</v-icon>KIF-összekötés törlése</v-btn>
                </td>
              </tr>
							<tr>
                <th style="text-align: right">Jegyzetek</th>
                <td style="text-align: center">
                  {{club.notes}}
                </td>
                <td style="text-align: center">
                  <v-btn rounded color="success" @click="dialogs.editClub.changedProperty='notes', dialogs.editClub.changedValue=club.notes,dialogs.editClub.show=true"><v-icon>fa-edit</v-icon>Szerkesztés</v-btn>
                </td>
              </tr>
              <tr>
                <th style="text-align: right">Régió</th>
                <td style="text-align: center" :colspan="this.$store.getters.userRole>=roles.nationalCoordinator?1:2">
                  {{club.Region.name}}
                </td>
                <td style="text-align: center" v-if="this.$store.getters.userRole>=roles.nationalCoordinator">
                  <v-btn rounded color="success" @click="dialogs.editClub.changedProperty='RegionId', dialogs.editClub.changedValue='',dialogs.editClub.show=true"><v-icon>fa-pen</v-icon>Módosítás</v-btn>
                </td>
              </tr>
              <tr v-if="club.MIRClub.MIRContact.MIRAddress">
                <th style="text-align: right">Cím</th>
                <td style="text-align: center" colspan="2">
                  {{club.MIRClub.MIRContact.MIRAddress.postal_code+" "+club.MIRClub.MIRContact.MIRAddress.city+" "+club.MIRClub.MIRContact.MIRAddress.street+" "+club.MIRClub.MIRContact.MIRAddress.house_number}}
                </td>
              </tr>
              <tr v-if="club.MIRClub.MIRContact.mailing_address">
                <th style="text-align: right">Levelezési cím</th>
                <td style="text-align: center" colspan="2">
                  {{club.MIRClub.MIRContact.mailing_address.postal_code+" "+club.MIRClub.MIRContact.mailing_address.city+" "+club.MIRClub.MIRContact.mailing_address.street+" "+club.MIRClub.MIRContact.mailing_address.house_number}}
                </td>
              </tr>
              <tr v-if="club.MIRClub.MIRContact.billing_address">
                <th style="text-align: right">Számlázási cím</th>
                <td style="text-align: center" colspan="2">
                  {{club.MIRClub.MIRContact.billing_address.postal_code+" "+club.MIRClub.MIRContact.billing_address.city+" "+club.MIRClub.MIRContact.billing_address.street+" "+club.MIRClub.MIRContact.billing_address.house_number}}
                </td>
              </tr>
            </tbody>
          </v-simple-table>
        </v-tab-item><!--Egyesület-->
				<v-tab-item><!--Csoportok-->
					<groups :clubId="$route.params.id" :routeToClubOrSchool="true" :routeToLeader="true" :showStatistics="true"/>
				</v-tab-item>
				<v-tab-item><!--Felhasználók-->
					<users :clubId="$route.params.id"/>
				</v-tab-item>
      </v-tabs>
    </v-card>
    <template>
      <v-row justify="center">
        <v-dialog
          v-model="dialogs.editClub.show"
          persistent
          max-width="600px"
        >

          <v-card>
            <v-card-title>
              <span class="headline">{{dialogs.editClub.changedProperty=="adminId"?"Kölyökatléta-felelős":(dialogs.editClub.changedProperty=="chairmanId"?"Elnök":(dialogs.editClub.changedProperty=="RegionId"?"Régió":(dialogs.editClub.changedProperty=="departmentDirectorId"?"Szakosztályvezető":"Jegyzetek")))}} módosítása</span>
            </v-card-title>
            <v-card-text>
              <v-container>
                <v-select
                  v-if="dialogs.editClub.changedProperty!='RegionId'&&dialogs.editClub.changedProperty!='notes'"
                  rounded
                  outlined
                  :label="dialogs.editClub.changedProperty=='adminId'?'Kölyökatléta-felelős':(dialogs.editClub.changedProperty=='chairmanId'?'Elnök':'Szakosztályvezető')"
                  prepend-inner-icon="fa-user-tie"
                  v-model="dialogs.editClub.changedValue"
                  :items="users"
                  item-text="fullname"
                  item-value="id"
                />
                <v-select
                  v-else-if="dialogs.editClub.changedProperty!='notes'"
                  rounded
                  outlined
                  label="Régió"
                  prepend-inner-icon="fa-map-marked"
                  :items="regions"
                  item-text="name"
                  item-value="id"
                  v-model="dialogs.editClub.changedValue"
                />
								<v-textarea
									v-else
									rounded
									outlined
									label="Jegyzetek"
									prepend-inner-icon="fa-sticky-note"
									v-model="dialogs.editClub.changedValue"
								/>
              </v-container>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="blue darken-1"
                text
                @click="dialogs.editClub.show = false"
              >
                Mégsem
              </v-btn>
              <v-btn
                color="blue darken-1"
                text
                @click="editClub()"
              >
                Módosítás
              </v-btn>
            </v-card-actions>
          </v-card>
      </v-dialog>
    </v-row>

  </template>

	</div>
</template>

<script>
	import router from '../router';
	import groups from '../components/groups.vue'
	import users from '../components/users.vue'
	export default {
    props: ['id'],
		data: function(){
			return {
        club: {},
        users: [],
        regions: [],
        dialogs: {
          editClub: {
            show: false,
  					changedProperty: '',
  					changedValue: "",
          }
        },
      }
		},
		components: {
			groups,
			users
		},
		computed: {

		},
		watch: {

		},
		methods: {
      editClub: function() {
				var changed = {club: {}};
				changed.club[this.dialogs.editClub.changedProperty]=this.dialogs.editClub.changedValue;
				this.axios({url: "club/"+this.$route.params.id, method: "PUT", data: {...changed}}).then((response) => {
					this.dialogs.editClub.show=false;
          switch(this.dialogs.editClub.changedProperty) {
            case 'adminId':
              this.club.KAAdmin=this.users.find(u => u.id==this.dialogs.editClub.changedValue)
            break;
            case 'chairmanId':
              this.club.Chairman=this.users.find(u => u.id==this.dialogs.editClub.changedValue)
            break;
            case 'departmentDirectorId':
              this.club.DepartmentDirector=this.users.find(u => u.id==this.dialogs.editClub.changedValue)
            break;
            case 'RegionId':
              this.club.Region.name=this.regions.find(r => r.id==this.dialogs.editClub.changedValue).name
              this.club.Region.id=this.dialogs.editClub.changedValue
            break;
						case 'notes':
							this.club.notes=this.dialogs.editClub.changedValue
						break
          }
					this.$store.commit('setSnack','A módosítás sikeresen megtörtént')
				})
      }
		},
		mounted(){
			this.axios({url: "club/"+this.$route.params.id, method: "GET"}).then((response) => {
        if(response.data.success) {
          this.club=response.data.data.club;
          this.users=response.data.data.users
          this.regions=response.data.data.regions
        }
			})
		}
	}
</script>
