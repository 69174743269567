import roles from './roles'

var roleNames = []

roleNames[roles.parent] = "Szülő"
roleNames[roles.competitionHelper] = "Versenysegítő"
roleNames[roles.trainer] = "Csoportvezető"
roleNames[roles.clubAdmin] = "Egyesületvezető"
roleNames[roles.regionalCoordinator] = "Regionális koordinátor"
roleNames[roles.nationalCoordinator] = "Országos koordinátor"


export default roleNames;
