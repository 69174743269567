<template>
	<section>
    <v-data-table
      :items="users"
      :headers="tableHeaders"
      hide-default-footer
			disable-pagination
			sort-by="fullname"
    >
      <template v-slot:top>
				<v-row>
					<v-col cols="12" :sm="schoolId||clubId?12:11">
		        <v-text-field
		          v-model="options.search"
		          prepend-inner-icon="fa-search"
		          rounded
		          outlined
		          label="Keresés"
							@input="search()"
		        />
					</v-col>
					<v-col cols="12" v-if="!(schoolID||clubId)" :sm="1">
						<v-tooltip top>
							Hozzáadás
							<template v-slot:activator="{on, attrs}">
								<v-btn fab color="success" v-on="on" v-bind="attrs" @click="dialogs.newUser.show=true"><v-icon>fa-plus</v-icon></v-btn>
							</template>
						</v-tooltip>
					</v-col>
				</v-row>
      </template>

      <template v-slot:footer>
        <v-row align="center" justify="end">
          <v-col cols="12" sm="3" align="center">
            <v-select
              align="center"
              label="Felhasználók egy oldalon"
              :items="[10, 15, 25, 50]"
              v-model="options.itemsPerPage"
              prepend-inner-icon="fa-users"
              rounded
              outlined
              @change="options.page = options.page>Math.ceil(totalUsers/options.itemsPerPage)?Math.ceil(totalUsers/options.itemsPerPage):options.page, getUsers()"
            />
          </v-col>
          <v-col cols="12" sm="1" align="center" justify="center">
            {{(options.page-1)*options.itemsPerPage+1}}-{{((options.page-1)*options.itemsPerPage+options.itemsPerPage)>totalUsers?totalUsers:((options.page-1)*options.itemsPerPage+options.itemsPerPage)}}/{{totalUsers}}
          </v-col>
          <v-col cols="12" sm="2" align="center">
            <v-btn icon :disabled="options.page<2" @click="changePage(false)"><v-icon>fa-arrow-left</v-icon></v-btn>{{options.page}}. oldal<v-btn icon :disabled="options.page==Math.ceil(totalUsers/options.itemsPerPage)" @click="changePage(true)"><v-icon>fa-arrow-right</v-icon></v-btn>
          </v-col>
        </v-row>
      </template>
      <template v-slot:item.role="{item}">
        {{roleNames[item.role]}}
      </template>
      <template v-slot:item.details="{item}">
				<v-btn icon color="info" :href="$router.resolve({name: 'user', params: {id: item.id}}).href" target="_blank"><v-icon>fa-info-circle</v-icon></v-btn>
      </template>
    </v-data-table>
		<v-row justify="center">
			<v-dialog
				v-model="dialogs.newUser.show"
				persistent
				max-width="600px"
			>

				<v-card>
					<v-card-title>
						<span class="headline">Új felhasználó</span>
					</v-card-title>
					<v-card-text>
						<v-container>
							<v-row>
								<v-col cols="12" sm="4">
									<v-text-field
										prepend-inner-icon="fa-user-graduate"
										label="Titulus"
										v-model="dialogs.newUser.user.title"
										rounded
										outlined
									/>
								</v-col>
								<v-col cols="12" sm="8">
									<v-text-field
										prepend-inner-icon="fa-user"
										label="Vezetéknév"
										v-model="dialogs.newUser.user.lastname"
										rounded
										outlined
										@input="queryMIR"
									/>
								</v-col>
							</v-row>

							<v-text-field
								prepend-inner-icon="fa-user"
								label="Keresztnév"
								v-model="dialogs.newUser.user.firstname"
								rounded
								outlined
								@input="queryMIR"
							/>

							<v-text-field
								prepend-inner-icon="fa-user-plus"
								label="Egyéb név"
								v-model="dialogs.newUser.user.othername"
								rounded
								outlined
							/>
							<v-menu
								v-model="dialogs.newUser.showBirthdatePicker"
								:close-on-content-click="false"
								:nudge-right="40"
								transition="scale-transition"
								offset-y
								min-width="auto"
							>
								<template v-slot:activator="{ on, attrs }">
									<v-text-field
										v-model="dialogs.newUser.user.birthdate"
										label="Születésnap"
										prepend-inner-icon="fa-calendar"
										readonly
										rounded
										outlined
										v-bind="attrs"
										v-on="on"
									></v-text-field>
								</template>
								<v-date-picker
									v-model="dialogs.newUser.user.birthdate"
									:first-day-of-week="1"
									@input="dialogs.newUser.showBirthdatePicker = false, queryMIR()"
								></v-date-picker>
							</v-menu>

							<v-text-field
								prepend-inner-icon="fa-at"
								label="Email-cím"
								v-model="dialogs.newUser.user.email"
								rounded
								outlined
							/>


							<v-text-field
								prepend-inner-icon="fa-user"
								label="Felhasználónév"
								v-model="dialogs.newUser.user.username"
								rounded
								outlined
							/>

							<v-select
								prepend-inner-icon="fa-user-tag"
								label="Jogkör"
								v-model="dialogs.newUser.user.role"
								rounded
								outlined
								:items="roleSelect"
								item-text="roleName"
								item-value="role"
							/>

						</v-container>
					</v-card-text>
					<v-card-actions>
						<span :class="(dialogs.newUser.queryMIR.inProgress?'primary':(dialogs.newUser.queryMIR.MIRUser&&dialogs.newUser.queryMIR.club?'success':(dialogs.newUser.queryMIR.MIRUser?'warning':'red')))+'--text'">
							<v-progress-circular indeterminate color="primary" v-if="dialogs.newUser.queryMIR.inProgress"/>
							<v-icon v-else :color="dialogs.newUser.queryMIR.MIRUser&&dialogs.newUser.queryMIR.club?'success':(dialogs.newUser.queryMIR.MIRUser?'warning':'red')">
								{{dialogs.newUser.queryMIR.MIRUser?'fa-check-circle':'fa-times-circle'}}
							</v-icon>
							{{dialogs.newUser.queryMIR.inProgress?'MIR keresés folyamatban':(dialogs.newUser.queryMIR.MIRUser?(dialogs.newUser.queryMIR.club?'MIR OK!':'MIR Egyesület nem található'):'Nem található a MIR-ben ilyen felhasználó')}}
						</span>
						<v-spacer></v-spacer>
						<v-btn
							color="blue darken-1"
							text
							@click="dialogs.newUser.show = false, dialogs.newUser.user.clubId=undefined, dialogs.newUser.user.firstname='',dialogs.newUser.user.lastname='',dialogs.newUser.user.email='',dialogs.newUser.user.username='',dialogs.newUser.user.role=0,dialogs.newUser.user.birthdate='2015-09-20'"
						>
							Mégsem
						</v-btn>
						<v-btn
							color="blue darken-1"
							text
							@click="addUser()"
							v-if="dialogs.newUser.user.firstname!=''&&dialogs.newUser.user.lastname!=''&&dialogs.newUser.user.email!=''&&dialogs.newUser.user.username!=''&&dialogs.newUser.user.role>=0&&dialogs.newUser.user.birthdate!=''&&!dialogs.newUser.queryMIR.inProgress&&dialogs.newUser.queryMIR.club"
						>
							Hozzáadás
						</v-btn>
						<v-btn
							color="blue darken-1"
							text
							@click="selectClubDialog()"
							v-else-if="dialogs.newUser.user.firstname!=''&&dialogs.newUser.user.lastname!=''&&dialogs.newUser.user.email!=''&&dialogs.newUser.user.username!=''&&dialogs.newUser.user.role>=0&&dialogs.newUser.user.birthdate!=''&&!dialogs.newUser.queryMIR.inProgress&&!dialogs.newUser.queryMIR.club"
						>
							Tovább
						</v-btn>
					</v-card-actions>
				</v-card>
			</v-dialog>
			<v-dialog
				v-model="dialogs.selectClub.show"
				persistent
				max-width="600px"
			>

				<v-card>
					<v-card-title>
						<span class="headline">Egyesület kiválasztása</span>
					</v-card-title>
					<v-card-text>
						<v-container>
							<v-select
								:items="clubs"
								item-value="id"
								item-text="name"
								v-model="dialogs.newUser.user.clubId"
								prepend-inner-icon="fa-map-marker"
								rounded
								outlined
								label="Egyesület"
							/>
						</v-container>
					</v-card-text>
					<v-card-actions>
						<v-spacer></v-spacer>
						<v-btn
							color="blue darken-1"
							text
							@click="dialogs.selectClub.show = false, dialogs.newUser.user.clubId=undefined, dialogs.newUser.user.firstname='',dialogs.newUser.user.lastname='',dialogs.newUser.user.email='',dialogs.newUser.user.username='',dialogs.newUser.user.role=0,dialogs.newUser.user.birthdate='2015-09-20'"
						>
							Mégsem
						</v-btn>
						<v-btn
							color="blue darken-1"
							text
							@click="addUser()"
							v-if="dialogs.newUser.user.clubId!==undefined"
						>
							Hozzáadás
						</v-btn>

					</v-card-actions>
				</v-card>
			</v-dialog>

		</v-row>

  </section>
</template>

<script>
  const headerProps = {
    align: "center",
    sortable: false,
    filterable: false,
    groupable: false
  }
  import roleNames from '../plugins/roleNames';
	export default {
		props: ['schoolId', 'clubId'],
		data: function(){
			return {
        users: [],
				clubs: [],
        options: {
          page: 1,
          itemsPerPage: 25,
          search: ""
        },
        totalUsers: 1,
        tableHeaders: [
          {
            text: "NÉV",
            value: "fullname",
						...headerProps
          },
          {
            text: "EMAIL-CÍM",
            value: "email",
            ...headerProps
          },
          {
            text: "FELHASZNÁLÓNÉV",
            value: "username",
            ...headerProps
          },
          {
            text: "JOGKÖR",
            value: "role",
            ...headerProps
          },
          {
            text: "RÉSZLETEK",
            value: "details",
            ...headerProps
          }
        ],
        roleNames,
				dialogs: {
					newUser: {
						show: false,
						showBirthdatePicker: false,
						user: {
							title: '',
							firstname: '',
							lastname: '',
							othername: '',
							email: '',
							username: '',
							role: -1,
							birthdate: '2015-09-20',
							clubId: undefined
						},
						queryMIR: {
							inProgress: false,
							club: false,
							MIRUser: false
						}
					},
					selectClub: {
						show: false
					}
				}
      }
		},
		computed: {
			roleSelect: function() {
				var roles = []
			  this.roleNames.forEach((val, index) => {
					if(index<this.$store.getters.userRole&&index!=this.roles.parent)
						roles.push( {role: index, roleName: val})
				})
				return roles;
			}
		},
		watch: {

		},
		methods: {
			addUser: function() {
				this.axios({url: "user/", method: "POST", data: {user: this.dialogs.newUser.user}}).then((response) => {
					if(response.data.success) {
						this.users.push({...this.dialogs.newUser.user, fullname: this.dialogs.newUser.user.title+" "+this.dialogs.newUser.user.lastname+" "+this.dialogs.newUser.user.firstname+" "+this.dialogs.newUser.user.othername, id: response.data.data.id})
						this.dialogs.newUser.user={
							title: '',
							firstname: '',
							lastname: '',
							othername: '',
							email: '',
							username: '',
							role: -1,
							birthdate: '2015-09-20',
							clubId: undefined
						};
						this.dialogs.newUser.show=false;
						this.dialogs.selectClub.show=false;
						this.$store.commit('setSnack','A hozzáadás sikeresen megtörtént.')

					}
				})
			},
      changePage: function(plus) {
        this.options.page+=(plus?1:-1)
        this.getUsers()
      },
			search: function() {
				this.options.page=1;
				this.getUsers()
			},
      getUsers: function() {
        this.axios({url: "user/list", method: "GET", params:{ ...this.options, ...(this.clubId!==undefined||this.schoolId!==undefined?{...(this.clubId!==undefined?{clubId:this.clubId}:{schoolId:this.schoolId})}:{})}}).then((response) => {
          if(response.data.success) {
            this.users=response.data.data.users
            this.totalUsers=response.data.data.totalUsers
          }
  			})
      },
			selectClubDialog: function() {
				if(this.clubs.length>0) {
					this.dialogs.newUser.show=false;
					this.dialogs.selectClub.show=true;
				} else this.axios({url: "club/addUser", method: "GET"}).then(response => {
					if(response.data.success) {
						this.clubs=response.data.data.clubs;
						this.clubs.push({id: null, name: '- Nincs egyesület'})
						this.dialogs.newUser.show=false;
						this.dialogs.selectClub.show=true;
					}
				})
			},
			queryMIR: function() {
				if(this.dialogs.newUser.user.firstname!=''&&this.dialogs.newUser.user.lastname!=''&&this.dialogs.newUser.user.birthdate!='') {
					this.dialogs.newUser.queryMIR.MIRUser=false;
					this.dialogs.newUser.queryMIR.club=false;
					this.dialogs.newUser.queryMIR.inProgress=true;
					this.axios({url: "user/querymir", method: 'GET', params: {firstname: this.dialogs.newUser.user.firstname, lastname: this.dialogs.newUser.user.lastname, birthdate: this.dialogs.newUser.user.birthdate}}).then(response => {
						if(response.data.success) {
							this.dialogs.newUser.queryMIR.MIRUser=response.data.data.user;
							this.dialogs.newUser.queryMIR.club=response.data.data.club;
							this.dialogs.newUser.queryMIR.inProgress=false;
						}
					})
				}
			}
		},
		mounted(){
      this.getUsers();
		}
	}
</script>
