<template>
	<div>
		<template>
      <v-data-table
        :headers="tables.groups.headers"
				:items="groups"
				hide-default-footer
				disable-pagination
      >
	      <template v-slot:top>
					<v-row v-if="showStatistics">
	          <v-col cols="12" :sm="6">
	            <v-menu
	              v-model="menus.start"
	              :close-on-content-click="false"
	              :nudge-right="40"
	              transition="scale-transition"
	              offset-y
	              min-width="auto"
	            >
	              <template v-slot:activator="{ on, attrs }">
	                <v-text-field
	                  v-model="options.startDate"
	                  label="Kezdő időpont"
	                  prepend-inner-icon="fa-calendar"
	                  readonly
	                  v-bind="attrs"
	                  v-on="on"
	                  rounded
	                  outlined
	                />
	              </template>
	              <v-date-picker
	                v-model="options.startDate"
	                :first-day-of-week="1"
	                @input="menus.start = false, getGroups()"
	              />
	            </v-menu>
	          </v-col>
	          <v-col cols="12" :sm="6">
	            <v-menu
	              v-model="menus.end"
	              :close-on-content-click="false"
	              :nudge-right="40"
	              transition="scale-transition"
	              offset-y
	              min-width="auto"
	            >
	              <template v-slot:activator="{ on, attrs }">
	                <v-text-field
	                  v-model="options.endDate"
	                  label="Befejező időpont"
	                  prepend-inner-icon="fa-calendar"
	                  readonly
	                  v-bind="attrs"
	                  v-on="on"
	                  rounded
	                  outlined
	                />
	              </template>
	              <v-date-picker
	                v-model="options.endDate"
	                :first-day-of-week="1"
	                @input="menus.end = false, getGroups()"
	              />
	            </v-menu>
	          </v-col>
					</v-row>
					<v-row>
						<v-col cols="12" :sm="$store.getters.userRole>=roles.clubAdmin&&!(clubId||schoolId||leaderId) ? 8 : 12">
			      <!--  <v-text-field
			          v-model="options.search"
			          label="Keresés"
			          prepend-inner-icon="fa-search"
			          class="mx-4"
								rounded
								outlined
								@change=""
			        />-->
						</v-col>
						<v-col cols="12" v-if="$store.getters.userRole>=roles.clubAdmin && !(clubId||schoolId||leaderId)" sm="3">
			        <v-checkbox
								v-model="options.onlyLedByMe"
								label="Csak a saját csoportjaim látszódjanak"
								@change="getGroups()"
							/>
						</v-col>
						<v-col cols="12" sm="1" v-if="!(clubId||schoolId||leaderId)">
							<v-tooltip top>
								Hozzáadás
								<template v-slot:activator="{on, attrs}">
									<v-btn fab color="success" v-on="on" v-bind="attrs" @click="dialog.show=true"><v-icon>fa-plus</v-icon></v-btn>
								</template>
							</v-tooltip>
						</v-col>
					</v-row>
	      </template>
				<template v-slot:item.kidAthleteCount="{item}">
					<span v-if="item.kidAthleteCount<config.minKidAthletesInAGroup" class="red--text">{{item.kidAthleteCount}} (NEM TELJES CSOPORT)</span>
					<span v-else>{{item.kidAthleteCount}}</span>
				</template>
				<template v-slot:item.trainings="{item}">
					<v-chip-group>
						<v-tooltip top v-for="t in item.Trainings" :key="t.dayOfWeek">
							{{t.place}}
							<template v-slot:activator="{on, attrs}">
								<v-chip
									v-on="on"
									v-bind="attrs"
									color="primary"
								>
									{{dayOfWeek(t.dayOfWeek)}} {{t.time}}
								</v-chip>
							</template>
						</v-tooltip>
					</v-chip-group>
				</template>
				<template v-slot:item.clubOrSchool="{item}" v-if="(routeToClubOrSchool||((!options.onlyLedByMe)&&$store.getters.userRole>=roles.regionalCoordinator))&&!(schoolId||clubId)">
					<a :href="item.MIRClubId ? $router.resolve({name: 'club', params: {id: item.MIRClubId}}).href : $router.resolve({name: 'school', params: {id: item.schoolId}}).href" target="_blank">{{item.clubOrSchool}}</a>
				</template>
				<template v-slot:item.leaderFullname="{item}" v-if="(routeToLeader||((!options.onlyLedByMe)&&$store.getters.userRole>=roles.regionalCoordinator))&&!leaderId">
					<!--<a v-if="$store.getters.userId!=item.leaderId" :href="$router.resolve({name: 'user', params: {id: item.leaderId}}).href" target="_blank">{{item.leaderFullname}}</a>-->
					<span>{{item.leaderFullname}}</span>
				</template>
				<template v-slot:item.view="{item}">
					<v-btn icon :href="$router.resolve({name: 'group', params: {id: item.id}}).href" target="_blank"><v-icon>fa-eye</v-icon></v-btn>
				</template>
        <template v-slot:footer>
					<div>
						Kölyökatléták száma a megjelenített csoportokban: {{groups.reduce((total, group) => total+(group.kidAthleteCount*1.0),0)}}
					</div>
					<div v-if="showStatistics">
						Megtartott foglalkozások száma a megjelenített csoportokban: {{groups.reduce((total, group) => total+(group.attendanceRegisterCount*1.0),0)}}

					</div>
          <v-row align="center" justify="end">
            <v-col cols="12" sm="3" align="center">
              <v-select
                align="center"
                label="Csoportok egy oldalon"
                :items="[{text: 10, value: 10}, {text: 15, value: 15}, {text: 25, value: 25}, {text: 50, value: 50}, {text: 'Mind', value: 0}]"
                v-model="options.itemsPerPage"
                prepend-inner-icon="fa-users"
                rounded
                outlined
                @change="options.page = options.page>Math.ceil(totalGroups/options.itemsPerPage)?Math.ceil(totalGroups/options.itemsPerPage):options.page, getGroups()"
              />
            </v-col>
            <v-col cols="12" sm="1" align="center" justify="center">
              {{(options.page-1)*options.itemsPerPage+1}}-{{options.itemsPerPage!=0?(((options.page-1)*options.itemsPerPage+options.itemsPerPage)>totalGroups?totalGroups:((options.page-1)*options.itemsPerPage+options.itemsPerPage)):totalGroups}}/{{totalGroups}}
            </v-col>
            <v-col cols="12" sm="2" align="center">
              <v-btn icon :disabled="options.page<2" @click="changePage(false)"><v-icon>fa-arrow-left</v-icon></v-btn>{{options.page}}. oldal<v-btn icon :disabled="options.page==Math.ceil(totalGroups/options.itemsPerPage)" @click="changePage(true)"><v-icon>fa-arrow-right</v-icon></v-btn>
            </v-col>
          </v-row>
        </template>
      </v-data-table>
		  <v-row justify="center">
		    <v-dialog
		      v-model="dialog.show"
		      persistent
		      max-width="600px"
		    >

		      <v-card>
		        <v-card-title>
		          <span class="headline">Új csoport</span>
		        </v-card-title>
		        <v-card-text>
		          <v-container>
								<v-text-field
									prepend-inner-icon="fa-users"
									label="Név"
									v-model="dialog.group.name"
									rounded
									outlined
								/>
								<v-text-field
									prepend-inner-icon="fa-info-circle"
									label="Rövid név"
									v-model="dialog.group.shortname"
									rounded
									outlined
								/>
		          </v-container>
		        </v-card-text>
		        <v-card-actions>
		          <v-spacer></v-spacer>
		          <v-btn
		            color="blue darken-1"
		            text
		            @click="dialog.show = false"
		          >
		            Mégsem
		          </v-btn>
		          <v-btn
		            color="blue darken-1"
		            text
		            @click="create()"
								v-if="dialog.group.name&&dialog.group.shortname"
		          >
		            Hozzáadás
		          </v-btn>
		        </v-card-actions>
		      </v-card>
	    </v-dialog>
	  </v-row>

	</template>
	</div>
</template>

<script>
	export default {
		props: ['clubId', 'schoolId', 'leaderId', 'routeToClubOrSchool', 'routeToLeader', 'showStatistics'],
		data: function(){
			return {
				menus: {
					start: false,
					end: false
				},
        groups: [],
        options: {
          page: 1,
          itemsPerPage: 25,
          search: "",
					onlyLedByMe: !(this.clubId||this.schoolId||this.leaderId),
					statistics: this.showStatistics===true,
					startDate: this.addMonths(new Date(), -12).toISOString().substring(0,10),
					endDate: (new Date()).toISOString().substring(0,10)
        },
        totalGroups: 1,
        tables: {
          groups: {
            headers: [
							{
								text: "Egyesület/iskola",
								align: "center",
								sortable: true,
								filterable: false,
								value: "clubOrSchool"
							},
							{
								text: "Csoportvezető",
								align: "center",
								sortable: true,
								filterable: false,
								value: "leaderFullname"
							},
              {
                text: "Név",
                align: "center",
                sortable: true,
                filterable: false,
                value: "name"
              },
              {
                text: "Rövid név",
                align: "center",
                sortable: false,
                filterable: false,
                value: "shortname"
              },
							{
								text: 'Létszám',
								align: "center",
								sortable: false,
								filterable: false,
								value: "kidAthleteCount"
							},
							...(this.showStatistics? [
								{
									text: 'Megtartott foglalkozások száma',
									align: "center",
									sortable: false,
									filterable: false,
									value: "attendanceRegisterCount"
								}/*,
								{
									text: 'Jelenléti arány',
									align: "center",
									sortable: false,
									filterable: false,
									value: "attendanceRate"
								}*/
							] :[]),
							{
								text: 'Edzések',
								align: "center",
								sortable: false,
								filterable: false,
								value: "trainings"
							},
							{
								text: "Megtekintés",
								align: "center",
								sortable: false,
								filterable: false,
								value: "view"
							}
            ]
          }
        },
        dialog: {
          show: false,
					group: {
						name: '',
						shortname: '',
					}
        }
      }
		},
		computed: {
			statisticColumns() {
				return this.showStatistics? [
					{
						text: 'Foglalkozások száma',
						align: "center",
						sortable: false,
						filterable: false,
						value: "attendanceRegistryCount"
					}/*,
					{
						text: 'Jelenléti arány',
						align: "center",
						sortable: false,
						filterable: false,
						value: "attendanceRate"
					}*/
				] :[]
			}
		},
		watch: {

		},
		methods: {
			create: function() {
				this.axios({url: "group", method: "POST", data: {group: this.dialog.group  }}).then((response) => {
					if(response.data.success) {
						this.groups.push({...this.dialog.group, id: response.data.data.id, MIRClub: response.data.data.MIRClub, kidAthleteCount: 0});
						this.dialog.show=false;
						this.dialog.group = { name: '', description: ''}
						this.$store.commit('setSnack','A hozzáadás sikeresen megtörtént.')
					}
				})
			},
      search: function() {
        this.options.page=1;
        this.getGroups()
      },
			changePage: function(plus) {
				this.options.page+=(plus?1:-1)
				this.getGroups()
			},
      getGroups: function() {
        this.axios({url: "group", method: "GET", params: this.options}).then((response) => {
          if(response.data.success) {
            this.groups=response.data.data.groups
            this.totalGroups = response.data.data.totalGroups
          }
  			})
      }
		},
		mounted(){
			if(this.clubId) this.options.clubId=this.clubId;
			else if(this.schoolId) this.options.schoolId=this.schoolId
			else if(this.leaderId) this.options.leaderId=this.leaderId
      this.getGroups()
		}
	}
</script>
